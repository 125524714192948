import React from 'react';
import { Form, Radio, Button, Divider, message } from 'antd';
import { AlipayCircleOutlined, WechatOutlined } from '@ant-design/icons';

import { buy as buyUrl, commission as commissionUrl } from '../../url.config';
import { reqAddOrder } from '../../shared/api';
import { ACTIVITY_TYPE, PAYMENT_MODE, ANNUAL_FEE } from '../../shared/constants';

class SmartWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: ANNUAL_FEE.SMARTWEBSITE,
      discountAmount: 0,
    };
  }

  onFinish = async (values) => {
    const { price, discountAmount } = this.state;
    const { year, activityType, paymentMode } = values;

    const result = await reqAddOrder({
      year,
      activityType: year >= 3 ? activityType : null,
      paymentMode,
    });
    if (result && result.success) {
      const currentUrl = buyUrl.children;
      this.props.history.push(currentUrl.pay.url, {
        orderNumber: result.orderNumber,
        actuallyPaid: price * year - discountAmount,
        productName: '智能建站正式版',
        year: year >= 3 && activityType === ACTIVITY_TYPE.BUY_THREE_GIVE_ONE_YEAR ? (year + 1) : year,
        paymentMode,
        extra: year >= 3 && activityType === ACTIVITY_TYPE.AGENCY ? '送“全程代办服务”' : null,
      });
    } else {
      message.error(result.message);
    }
  }

  getItemToChildren = (categorysData) => {
    const { price, discountAmount } = this.state;
    return ([
      <Form.Item className="form-item" key="year">
        <div className="title"><b>购买时长</b></div>
        <Form.Item
          noStyle
          name="year"
        >
          <Radio.Group>
            <Radio.Button value={1} className="radio">1年</Radio.Button>
            <Radio.Button value={2} className="radio">2年</Radio.Button>
            <Radio.Button value={3} className="radio">3年</Radio.Button>
            <Radio.Button value={4} className="radio">4年</Radio.Button>
            <Radio.Button value={5} className="radio">5年</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form.Item>,
      <Form.Item className="form-item" key="activityType">
        <div className="title"><b>选择优惠</b><mark>买 3 送 1</mark></div>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.year !== currentValues.year}
        >
          {({ getFieldValue }) => {
            const year = getFieldValue('year');
            return (
              <Form.Item
                noStyle
                name="activityType"
              >
                <Radio.Group
                  disabled={year <= 2}
                >
                  <Radio.Button value={ACTIVITY_TYPE.BUY_THREE_GIVE_ONE_YEAR}>
                    送 1 年服务
                  </Radio.Button>
                  <Radio.Button value={ACTIVITY_TYPE.AGENCY}>
                    送“全程代办服务”
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            );
          }}
        </Form.Item>
        <div className="tip"><a href={commissionUrl.url} target="_blank" rel="noopener noreferrer">了解全程代办服务 &gt;</a></div>
      </Form.Item>,
      <Form.Item className="form-item" key="payType">
        <div className="title"><b>应付金额</b></div>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.year !== currentValues.year}
        >
          {({ getFieldValue }) => {
            const year = getFieldValue('year');
            return (
              <div className="price">
                {
                  <span>{'￥'.concat((year * price - discountAmount).toFixed(2))}</span>
                }
                {
                  discountAmount > 0 ? `已优惠￥${discountAmount.toFixed(2)}` : null
                }
              </div>
            );
          }}
        </Form.Item>
      </Form.Item>,
      <Form.Item className="form-item" key="paymentMode">
        <div className="title"><b>付款方式</b></div>
        <Form.Item
          noStyle
          name="paymentMode"
        >
          <Radio.Group size="large">
            <Radio.Button value={PAYMENT_MODE.ALIPAY}>
              <AlipayCircleOutlined style={{ color: '#02A9F1', fontSize: '20px', marginRight: '7px' }} />
              支付宝
            </Radio.Button>
            <Radio.Button value={PAYMENT_MODE.WEIXINPAY}>
              <WechatOutlined style={{ color: '#43AE49', fontSize: '20px', marginRight: '7px' }} />
              微信支付
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form.Item>,
      <Form.Item key="submit">
        <Divider style={{margin: '45px 0 30px'}} />
        <Button
          className="form-item-submit"
          type="primary"
          htmlType="submit"
        >
          去付款
        </Button>
      </Form.Item>,
    ]);
  }

  render() {
    const item = this.getItemToChildren();
    return (
      <div style={{width: '100%'}}>
        <h1>智能建站正式版</h1>
        <Form
          name="order"
          layout="vertical"
          hideRequiredMark={true}
          initialValues={{
            year: 1,
            paymentMode: PAYMENT_MODE.ALIPAY,
            activityType: ACTIVITY_TYPE.BUY_THREE_GIVE_ONE_YEAR,
          }}
          onFinish={this.onFinish}
        >
          {item}
        </Form>
      </div>
    );
  }
}
export default SmartWebsite;