import React from 'react';
import { Steps, Input, Button, Divider, Modal, message, Popover } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { reqPayment } from '../../shared/api';
import { PAYMENT_MODE } from '../../shared/constants';

const { Step } = Steps;

class PaymentNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
      current: 0,
      loading: false,
      visibleContact: false,
    };
  }

  handleVisibleContactChange = visibleContact => {
    this.setState({ visibleContact });
  };

  handleOk = async () => {
    const { number } = this.state;
    const { orderData } = this.props;
    const { orderNumber } = orderData;

    this.setState({
      loading: true,
    });

    const result = await reqPayment(orderNumber, number);

    this.setState({
      loading: false,
    });

    if (result && result.success) {
      this.props.handleOk(orderNumber);
    } else {
      message.error(result.message);
    }
  }

  onFocusNumber = () => {
    this.setState({
      current: 1,
    })
  }

  onBlurNumber = () => {
    const { number } = this.state;
    this.setState({
      current: number ? 1 : 0,
    })
  }

  onChangeNumber = (e) => {
    const value = e.target.value;
    this.setState({
      number: value.trim(),
    })
  }

  showHelp = (type) => {
    Modal.info({
      width: 750,
      icon: null,
      title: type === PAYMENT_MODE.ALIPAY ? '支付宝订单号填写指南' : '微信转账单号填写指南',
      content: (
        <div style={{ margin: '25px 0' }}>
          {
            type === PAYMENT_MODE.ALIPAY
              ? <img src="http://img.yqlizhi.com/buy/help0.jpg" alt="支付宝订单号填写指南" style={{ width: '100%', maxWidth: '700px' }} />
              : <img src="http://img.yqlizhi.com/buy/help1.jpg" alt="微信转账单号填写指南" style={{ width: '100%', maxWidth: '700px' }} />
          }
        </div>
      ),
      okText: '关闭',
      maskClosable: true,
    });
  }
  
  render() {
    const { number, current, loading, visibleContact } = this.state;
    const { orderData } = this.props;
    const { actuallyPaid, productName, year, paymentMode, extra } = orderData;
    const voucher = paymentMode === PAYMENT_MODE.ALIPAY ? '支付宝订单号' : '微信转账单号';
    const paymentCode = paymentMode === PAYMENT_MODE.ALIPAY
      ? <img src="http://img.yqlizhi.com/qrcode_alipay.png" alt="支付宝付款码" />
      : <img src="http://img.yqlizhi.com/qrcode_weixinpay.png" alt="微信付款码" />;
    const step1 = (
      <div className="step1">
        {paymentCode}
        <div className="price">待支付：<span>￥{actuallyPaid.toFixed(2)}</span></div>
        <div>请确保支付金额与“待支付”金额一致，以免影响到账核实。</div>
        <div className="content">
          <p><span>商品名称：</span>{productName}</p>
          {
            year && year >= 1 ? <p><span>使用年限：</span>{year}年</p> : null
          }
          {
            extra ? <p><span>其他：</span>{extra}</p> : null
          }
        </div>
      </div>
    );
    const step2 = (
      <div className="step2">
        <Input
          type="number"
          pattern="[0-9]*"
          className="input"
          placeholder={`${voucher}最后6位数字`}
          onFocus={this.onFocusNumber}
          onBlur={this.onBlurNumber}
          onChange={this.onChangeNumber}
        />
        <br />
        <span onClick={() => this.showHelp(paymentMode)}>{voucher}填写指南</span>
      </div>
    );
    const contactContent = (
      <div className="pay-contact">
        <img src="http://img.yqlizhi.com/qrcode_kf.png" alt="微信" />
        <div>使用微信扫一扫<br />联系客服人员</div>
      </div>
    );
    return (
      <div className="pay">
        <div className="title">
          <span className="return" onClick={() => this.props.goBack()}>
            <ArrowLeftOutlined /><i>返回</i>
          </span>
          <span>{`提交“${voucher}”后，将在2小时内完成到账核实，核实成功即可开通服务。`}</span>
        </div>
        <Steps direction="vertical" current={current}>
          <Step title="扫描以下付款码 支付相应金额" description={step1} />
          <Step title={`支付成功 提交${voucher}`} description={step2} />
        </Steps>
        <Divider style={{ margin: '0 0 30px' }} />
        <Button
          key="ok"
          className="button"
          type="primary"
          disabled={loading || !number}
          loading={loading}
          onClick={this.handleOk}
        >
          已完成付款，提交核实
        </Button>
        <Popover
          placement="top"
          trigger="click"
          visible={visibleContact}
          content={contactContent}
          onVisibleChange={this.handleVisibleContactChange}
        >
          <Button
            key="help"
            className="button"
          >
            付款有疑问？客服来帮你
          </Button>
        </Popover>
      </div>
    );
  }
}
export default PaymentNumber;