import ajax from './ajax';

const BASE = 'http://api.yqlizhi.com/api';

// 获取用户信息
export const reqUser = () => ajax(BASE + '/UserInfo');

// 添加订单
export const reqAddOrder = (params) => ajax(BASE + '/UserOrder', { ...params }, 'POST');

// 支付订单
export const reqPayment = (orderNumber, paymentNumber) => ajax(BASE + '/UserOrder', { orderNumber, paymentNumber }, 'PUT');