import { POST_TYPE } from '../actionTypes';

const POST_RESULT = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
};

function resolvePostResult(actionType) {
  switch (actionType) {
    case POST_TYPE.GET_USER_SUCCESS:
      return POST_RESULT.SUCCESS;
    case POST_TYPE.GET_USER_ERROR:
      return POST_RESULT.ERROR;
    default:
      return POST_RESULT.DEFAULT;
  }
}

export default function user(state, action) {
  switch (action.type) {
    case POST_TYPE.GET_USER_DEFAULT:
    case POST_TYPE.GET_USER_SUCCESS:
    case POST_TYPE.GET_USER_ERROR:
      return {
        login: resolvePostResult(action.type),
        ...action.user,
      };
    default:
      return state || {
        login: resolvePostResult(action.type),
      };
  }
}