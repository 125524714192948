import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { getUserData } from './shared/redux/actions';
import { buy as buyUrl, login as loginUrl } from './url.config';
import * as c from './shared/cookies';

import Banner from './components/Banner';
import Pay from './components/Pay';
import SmartWebsite from './components/SmartWebsite';
import NotFound from './components/NotFound';

import './static/index.less';

class App extends React.Component {
  componentWillMount() {
    const login = this.props.user.login;
    if (login === 'default') {
      this.props.getUserData();
    }
  }

  goLogin = async () => {
    await c.setCookie('referrer', document.URL, null);
    window.location.href = loginUrl.url;
  }

  render() {
    const login = this.props.user.login;
    switch (login) {
      case 'default':
        return (
          <div
            style={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src="http://img.yqlizhi.com/smart-website/loading.gif" alt="登录中" />
          </div>
        );
      case 'error':
        this.goLogin();
        break;
      default:
        const currentUrl = buyUrl.children;
        return (
          <div className="page-wrapper">
            <div className="page-left-view">
              <Banner />
            </div>
            <div className="page-right-view">
              <BrowserRouter>
                <Switch>
                  <Route exact path={currentUrl.smartWebsite.url} component={SmartWebsite}></Route>
                  <Route exact path={currentUrl.pay.url} component={Pay}></Route>
                  <Route component={NotFound}></Route>
                </Switch>
              </BrowserRouter>
            </div>
          </div>
        );
    }
  }
}
export default connect(
  state => ({ user: state.user }),
  { getUserData }
)(App);