import React from 'react';
import { Redirect } from 'react-router-dom';

import { buy as buyUrl } from '../url.config';

export default class NotFound extends React.Component {
  render() {
    return (
      <Redirect to={buyUrl.children.smartWebsite.url} />
    );
  }
}