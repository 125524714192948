import { takeEvery, put } from 'redux-saga/effects';

import {
  GET_USER_DATA,
  LOGOUT_USER,
  POST_TYPE
} from './actionTypes';
import * as c from '../cookies';
import {
  reqUser,
} from '../api';

function* handleGetUserData(action) {
  const user = yield reqUser();
  if (user && user.success) {
    yield put({
      type: POST_TYPE.GET_USER_SUCCESS,
      user: {
        userId: user.userId,
        mobile: user.userPhone,
      },
    });
    return;
  } else {
    c.removeCookie('token');
  }

  yield put({
    type: POST_TYPE.GET_USER_ERROR,
    user: {},
  });
}

function* handleLogoutUser(action) {
  c.removeCookie('token');
  yield put({
    type: POST_TYPE.GET_USER_ERROR,
    user: {},
  });
}

export default function* () {
  yield takeEvery(GET_USER_DATA, handleGetUserData);
  yield takeEvery(LOGOUT_USER, handleLogoutUser);
}