export const ACTIVITY_TYPE = {
  GIVE_ONE_YEAR: 'GIVE_ONE_YEAR',
  BUY_THREE_GIVE_ONE_YEAR: 'BUY_THREE_GIVE_ONE_YEAR',
  AGENCY: 'AGENCY',
}

export const PAYMENT_MODE = {
  ALIPAY: 'ALIPAY',
  WEIXINPAY: 'WEIXINPAY',
}

export const ANNUAL_FEE = {
  SMARTWEBSITE: 800,
}