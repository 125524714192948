export const buy = {
  title: '订购中心',
  url: '/',
  children: {
    pay: {
      title: '付款',
      url: '/pay/',
    },
    smartWebsite: {
      title: '智能建站正式版',
      url: '/znjz/',
    },
  },
};

export const index = {
  title: '主页',
  url: 'http://www.yqlizhi.com',
};

export const login = {
  title: '登录注册',
  url: 'http://passport.yqlizhi.com',
};

export const pro = {
  title: '更多产品',
  url: 'http://www.yqlizhi.com',
};

export const commission = {
  title: '全程代办服务',
  url: 'http://znjz.yqlizhi.com/db',
}