import React from 'react';
import { Result, Button } from 'antd';

import { index as indexUrl } from '../../url.config';

import PaymentNumber from './PaymentNumber';

class Pay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: null,
    };
  }

  handleOk = (orderNumber) => {
    this.setState({ orderNumber });
  }
  
  render() {
    const { orderNumber } = this.state;
    if (orderNumber) {
      return (
        <div style={{width: '100%'}}>
          <Result
            status="success"
            title="订单提交成功"
            subTitle={<div>你的交易单号：{orderNumber}<br />我们将在2小时内完成到账核实，核实成功即可开通服务，请耐心等待。</div>}
            extra={[
              <Button key="return" type="primary" href={indexUrl.url}>返回首页</Button>,
              <Button key="buy" onClick={() => this.props.history.goBack()}>继续购买</Button>,
            ]}
          />
        </div>
      );
    } else {
      const orderData = this.props.location.state || {};
      return (
        <PaymentNumber
          goBack={() => this.props.history.goBack()}
          orderData={orderData}
          handleOk={this.handleOk}
        />
      );
    }
  }
}
export default Pay;