import {
  GET_USER_DATA,
  LOGOUT_USER,
} from './actionTypes';

export const getUserData = (data) => ({
  type: GET_USER_DATA,
  data,
});

export const logoutUser = (data) => ({
  type: LOGOUT_USER,
  data,
});