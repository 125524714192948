import axios from 'axios';
import qs from 'query-string';
import { message } from 'antd';

import * as c from '../cookies';

export const MethodType = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
};

export default function ajax(url, params = {}, method = MethodType.GET) {
  const apiToken = c.getCookie('token');
  const data = (method === MethodType.GET) ? 'params' : 'data';
  const headers = (method === MethodType.GET) ? {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': apiToken,
  }
    : {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': apiToken,
    };
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      [data]: (method === MethodType.GET) ? params : qs.stringify(params),
      headers,
    }).then(response => {
      resolve(response.data);
    }).catch(error => {
      console.dir(error);
      message.error(error.message);
      reject(error);
    });
  });
}