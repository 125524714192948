import React from 'react';

import { index as indexUrl, pro as proUrl } from '../../url.config';

export default class Banner extends React.Component {
  render() {
    return (
      <div className="banner">
        <a className="logo" href={indexUrl.url}>
          <img src="http://img.yqlizhi.com/smart-website/logo.svg" alt="logo" />
        </a>
        <div className="banner-content">
          <img src="http://img.yqlizhi.com/buy/banner.svg" alt="在线订购" />
        </div>
        <div className="link">
          <a href={proUrl.url} target="_blank" rel="noopener noreferrer">更多产品 ></a>
        </div>
      </div>
    );
  }
}